<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '../../../helpers/errorHandler';
import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Session Form",
  },
  data() {
    return {
      title: "Session",
      items: [
        {
          text: "Attendance",
          href: "/home",
        },
        {
          text: "Sessions",
          href: "/sessions",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      backendError: false,
      type: null,
      form:{
        center_id: null,
        dateTime: null,
        year: null,
        chapter_id: null,
        id: null
      },
      centerError: null,
      yearError: null,
      dateTimeError: null,
      chapterError: null,

    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed:{
    centers(){
      return this.$store.getters['option/centers'];
    },
    years(){
      return this.$store.getters['option/years'];
    },
    chapters(){
      return this.$store.getters['option/chapters'];
    },

  },
  methods:{
    validate(){
      if(this.form.center_id){
        this.centerError = true;
      }else{
        this.centerError = false;
      }
      if(this.form.year){
        this.yearError = true;
      }else{
        this.yearError = false;
      }
      if(this.form.dateTime){
        this.dateTimeError = true;
      }else{
        this.dateTimeError = false;
      }
      if(this.form.chapter_id){
        this.chapterError = true;
      }else{
        this.chapterError = false;
      }
      return this.centerError && this.dateTimeError && this.yearError && this.chapterError ;
    },
    handleSubmit() {
      console.log(this.form.dateTime)
      if(this.validate()){
        // requests for the create
        this.form.date_time = this.form.dateTime;
        this.$store.dispatch("session/addSession",this.form).then(
           ()=>{
            this.$router.push('/sessions');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
    handleUpdate(){
      if(this.validate()){
        // requests for the create
        this.form.date_time = this.form.dateTime;
        this.$store.dispatch("session/editSession",this.form).then(
           ()=>{
            this.$router.push('/sessions');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
    clearSelectError(){
      this.centerError = null;
    },
    async clearSelectError2(){
      this.yearError = null;
      await this.$store.dispatch('option/clearChaptersOption')
      if(this.form.year){
        await this.$store.dispatch('option/getYearChapterOption',this.form.year).then( ()=>{}).catch( err => {
          errorHandler.methods.errorMessage(err);
        })
      }
    },
    clearSelectError4(){
      this.dateTimeError = null;
    },
    async clearSelectError5(){
      this.chapterError = null;
    },

  },
  async created(){
    await this.$store.dispatch('option/getCenterOption').then( ()=>{}).catch( err => {
        errorHandler.methods.errorMessage(err);
      });
    await this.$store.dispatch('option/getYearOption').then( ()=>{}).catch( err => {
        errorHandler.methods.errorMessage(err);
      });
    if(this.$route.params.id){
      this.type = 'edit';
        await this.$store.dispatch('session/getSessionInfo',this.$route.params.id).then( async token =>{
            await this.$store.dispatch('option/getYearChapterOption',token.year).then( ()=>{}).catch( err => {
              errorHandler.methods.errorMessage(err);
            });
            this.form.center_id = token.center_id;
            this.form.year = token.year;
            this.form.id = this.$route.params.id;
            this.form.dateTime = moment(token.date_time).format('YYYY-MM-DDTHH:SS');
            this.form.chapter_id = token.chapter.id;
            console.log(this.form);
        }).catch( err =>{
            errorHandler.methods.error(err);
        })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create session</h2>
                    <h2 v-if="type == 'edit'">Edit session</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6" >
                      <b-form-group label="Center:" label-for="input-C" >
                        <b-form-select  v-model="form.center_id" :options="this.centers" :state="centerError" @change="clearSelectError"></b-form-select>
                        <!-- <b-form-select  v-if="type == 'edit' && form.center_id " v-model="form.center_id" :options="this.centers" :state="assignToError" @change="clearSelectError"></b-form-select> -->
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group label="Year:" label-for="input-C" >
                        <b-form-select   v-model="form.year" :options="this.years" :state="yearError" @change="clearSelectError2"></b-form-select>
                        <!-- <b-form-select  v-if="type == 'edit' && form.center_id " v-model="form.center_id" :options="this.centers" :state="assignToError" @change="clearSelectError"></b-form-select> -->
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group  label="Date and time" label-for="date-time">
                        <b-form-input id="date-time" v-model="form.dateTime" type="datetime-local" :state="dateTimeError" @change="clearSelectError4"></b-form-input>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="form.year && this.chapters.length">
                      <b-form-group label="Lecture:" label-for="input-C" >
                        <b-form-select  v-model="form.chapter_id" :options="this.chapters" :state="chapterError" @change="clearSelectError5"></b-form-select>
                        <!-- <b-form-select  v-if="type == 'edit' && form.center_id " v-model="form.center_id" :options="this.centers" :state="assignToError" @change="clearSelectError"></b-form-select> -->
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>